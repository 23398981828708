import React from 'react';
import styled from 'lib/styled';
import { style } from '../../../components/Shared/Style';
import { DetailHeader } from '../../../components/Texts/DetailHeader';
import { L } from '@app/shared/lib/i18n';
import { ContentInformationDocumentDetail } from '@app/api/models/ContentInformation';
import { usePromise } from '../../../lib/react';
import useGetDocumentUrl from '../../../areas/documents/useGetDocumentUrl';
import { colors } from '../../Shared/Style/colors';
import DocumentItemContext from './DocumentItemContext';
import { DocumentDetail } from '@app/api/public/DocumentsApi';

const DocumentGroups = styled.div({
    width: '100%',
    marginTop: style.margin.m,
});

const Wrapper = styled.div({
    width: '100%',
});

function DocumentGroupBy({
    documents,
    ...rest
}: { documents: ContentInformationDocumentDetail[] } & React.HTMLAttributes<HTMLDivElement>) {
    const { getSignedDocuments } = useGetDocumentUrl();

    const [ready, data] = usePromise(async () => {
        return await getSignedDocuments(documents);
    }, [documents]);

    if (!ready) return null;

    const document_groups = new Map<string, DocumentDetail[]>();
    data.forEach((doc) => {
        if (!document_groups.get(doc.document_type)) {
            document_groups.set(doc.document_type, []);
        }
        document_groups.get(doc.document_type)?.push(doc);
    });

    const r = [...document_groups.entries()].map(([name, documents], index) => (
        <DocumentGroups key={index}>
            {name !== 'null' && <DetailHeader color={style.colors.black90} padding={'0px'} title={L(`${name}`)} />}
            {documents.map((doc) => (
                <DocumentItemContext
                    style={{
                        borderBottom: `1px solid ${colors.divider}`,
                    }}
                    key={doc.id}
                    doc={doc}
                />
            ))}
        </DocumentGroups>
    ));
    return <Wrapper {...rest}>{r}</Wrapper>;
}

export default DocumentGroupBy;
